import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "m-connect" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OAuthConnect = _resolveComponent("OAuthConnect")!
  const _component_OAuthMultiConnect = _resolveComponent("OAuthMultiConnect")!
  const _component_MultiConnect = _resolveComponent("MultiConnect")!
  const _component_MinimalConnect = _resolveComponent("MinimalConnect")!
  const _component_Connect = _resolveComponent("Connect")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.detectApp || (_ctx.clientId && _ctx.appName))
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (!_ctx.multi)
            ? (_openBlock(), _createBlock(_component_OAuthConnect, {
                key: 0,
                "show-balance": _ctx.showBalance,
                "connect-wallet-image": _ctx.connectWalletImage,
                "show-chain": _ctx.showChain,
                avatar: _ctx.avatar,
                "fallback-provider": _ctx.fallbackProvider,
                network: _ctx.network,
                "parent-frame-url": _ctx.parentFrameUrl,
                "auto-reconnect": _ctx.autoReconnect,
                "override-connect-text": _ctx.overrideConnectText,
                "strict-auth": _ctx.strictAuth,
                "delay-auth": _ctx.delayAuth,
                "client-id": _ctx.clientId,
                "app-name": _ctx.appName,
                "grant-type": _ctx.grantType,
                message: _ctx.message
              }, null, 8, ["show-balance", "connect-wallet-image", "show-chain", "avatar", "fallback-provider", "network", "parent-frame-url", "auto-reconnect", "override-connect-text", "strict-auth", "delay-auth", "client-id", "app-name", "grant-type", "message"]))
            : (_openBlock(), _createBlock(_component_OAuthMultiConnect, {
                key: 1,
                "show-balance": _ctx.showBalance,
                "connect-wallet-image": _ctx.connectWalletImage,
                "show-chain": _ctx.showChain,
                avatar: _ctx.avatar,
                "fallback-provider": _ctx.fallbackProvider,
                network: _ctx.network,
                "parent-frame-url": _ctx.parentFrameUrl,
                "auto-reconnect": _ctx.autoReconnect,
                "override-connect-text": _ctx.overrideConnectText,
                "strict-auth": _ctx.strictAuth,
                "delay-auth": _ctx.delayAuth,
                "client-id": _ctx.clientId,
                "app-name": _ctx.appName,
                "grant-type": _ctx.grantType,
                message: _ctx.message
              }, null, 8, ["show-balance", "connect-wallet-image", "show-chain", "avatar", "fallback-provider", "network", "parent-frame-url", "auto-reconnect", "override-connect-text", "strict-auth", "delay-auth", "client-id", "app-name", "grant-type", "message"]))
        ], 64))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_ctx.multi)
            ? (_openBlock(), _createBlock(_component_MultiConnect, {
                key: 0,
                "show-balance": _ctx.showBalance,
                "connect-wallet-image": _ctx.connectWalletImage,
                "show-chain": _ctx.showChain,
                avatar: _ctx.avatar,
                "fallback-provider": _ctx.fallbackProvider,
                network: _ctx.network,
                "parent-frame-url": _ctx.parentFrameUrl,
                "auto-reconnect": _ctx.autoReconnect,
                "override-connect-text": _ctx.overrideConnectText
              }, null, 8, ["show-balance", "connect-wallet-image", "show-chain", "avatar", "fallback-provider", "network", "parent-frame-url", "auto-reconnect", "override-connect-text"]))
            : (_ctx.minimal)
              ? (_openBlock(), _createBlock(_component_MinimalConnect, {
                  key: 1,
                  "show-balance": _ctx.showBalance,
                  "connect-wallet-image": _ctx.connectWalletImage,
                  "show-chain": _ctx.showChain,
                  avatar: _ctx.avatar,
                  "fallback-provider": _ctx.fallbackProvider,
                  network: _ctx.network,
                  "parent-frame-url": _ctx.parentFrameUrl,
                  "auto-reconnect": _ctx.autoReconnect,
                  "override-connect-text": _ctx.overrideConnectText
                }, null, 8, ["show-balance", "connect-wallet-image", "show-chain", "avatar", "fallback-provider", "network", "parent-frame-url", "auto-reconnect", "override-connect-text"]))
              : (_openBlock(), _createBlock(_component_Connect, {
                  key: 2,
                  "show-balance": _ctx.showBalance,
                  "connect-wallet-image": _ctx.connectWalletImage,
                  "show-chain": _ctx.showChain,
                  avatar: _ctx.avatar,
                  "fallback-provider": _ctx.fallbackProvider,
                  network: _ctx.network,
                  "parent-frame-url": _ctx.parentFrameUrl,
                  "auto-reconnect": _ctx.autoReconnect,
                  "override-connect-text": _ctx.overrideConnectText
                }, null, 8, ["show-balance", "connect-wallet-image", "show-chain", "avatar", "fallback-provider", "network", "parent-frame-url", "auto-reconnect", "override-connect-text"]))
        ], 64))
  ]))
}