import manifoldCSSReset from '@manifoldxyz/css-reset';
import MConnect from '@/exports/MConnect.vue';
import { renderComponentWithApp } from '../build/mount';
import { MANIFOLD_LOAD_REQUEST, MManifoldRefreshWidgetsEvent, WIDGET } from './common/constants';

manifoldCSSReset();

// For usage outside of a Vue application but still in JS:
// if interval = 0, it means we have purposefully reset it, since setInterval
// only returns non-zero integer numbers
let mConnectionInterval!: number;

function replaceWidget (event: Event | MManifoldRefreshWidgetsEvent) {
  // if ever we were to need to inject/nest other widget scripts upon load
  // injectOtherWidgetScripts()

  let timeout = 10;
  if (event.type === MANIFOLD_LOAD_REQUEST) {
    const detail = (event as MManifoldRefreshWidgetsEvent).detail;
    if (detail && detail.widget && detail.widget !== WIDGET) {
      // refresh request for different widget
      return;
    }

    // clear current load request if it exists and reload
    if (mConnectionInterval) {
      clearInterval(mConnectionInterval);
      mConnectionInterval = 0;
    }
  }
  if (!mConnectionInterval) {
    mConnectionInterval = window.setInterval(() => {
      timeout--;

      // Grab all empty widget containers from the DOM
      const elements = document.querySelectorAll("[data-widget='m-connect']");

      if (timeout === 0) {
        clearInterval(mConnectionInterval);
        console.error('MConnect failed to mount due to time out.');
        return;
      }

      if (elements.length) {
        // No need to keep checking for elements as we've found them all.
        clearInterval(mConnectionInterval);

        for (let i = 0; i < elements.length; i++) {
          const el = elements[i];
          // if el has already had its children injected, skip
          // ASSUMPTION: first child of div would be the MConnect component
          if ((el.firstChild as HTMLElement)?.classList?.contains('m-connect')) {
            continue;
          }
          // grab the DOM element's data- properties to use as propsData
          const data = (el as HTMLElement).dataset;

          // be sure to give proper fallback type
          const propsData = {
            fallbackProvider: ('fallbackProvider' in data) ? data.fallbackProvider : undefined,
            network: data.network ? parseInt(data.network) : undefined,
            parentFrameUrl: ('parentFrameUrl' in data) ? data.parentFrameUrl : undefined,
            avatar: ('avatar' in data) ? data.avatar : undefined,
            multi: data.multi ? JSON.parse(data.multi) : false,
            minimal: data.minimal ? JSON.parse(data.minimal) : false,
            autoReconnect: data.autoReconnect ? JSON.parse(data.autoReconnect) : true,
            strictAuth: data.strictAuth ? JSON.parse(data.strictAuth) : true,
            delayAuth: data.delayAuth ? JSON.parse(data.delayAuth) : false,
            message: ('message' in data) ? data.message : undefined,
            showChain: ('showChain' in data),
            showBalance: ('showBalance' in data),
            clientId: ('clientId' in data) ? data.clientId : undefined,
            appName: ('appName' in data) ? data.appName : undefined,
            detectApp: data.detectApp ? JSON.parse(data.detectApp) : false,
            grantType: ('grantType' in data) ? data.grantType : undefined,
            redirectUri: ('redirectUri' in data) ? data.redirectUri : undefined,
            overrideConnectText: ('overrideConnectText' in data) ? data.overrideConnectText : undefined,
            connectWalletImage: ('connectWalletImage' in data) ? data.connectWalletImage : undefined
          };

          renderComponentWithApp({
            el: el,
            component: MConnect,
            props: propsData
          });
        }
      }
    }, 1000);
  }
}

// Inject other widget scripts using this function and the example pattern to avoid multiple loads.
// function injectOtherWidgetScripts () {
//   // ex: manifold-connect-widgets
//   const connectWidgetSrc = 'https://connect.manifoldxyz.dev/2.0.12/connect.umd.min.js';
//   // checks for exact version. opt for fuzzy finding if you're okay with any version.
//   const connectWigetScriptEl = document.querySelector(`script[src*="${connectWidgetSrc}"]`);
//   // inject the JS just once into the <head>
//   if (!connectWigetScriptEl) {
//     const scriptElemenToAdd = document.createElement('script');
//     scriptElemenToAdd.src = connectWidgetSrc;
//     scriptElemenToAdd.onload = () => {
//       window.dispatchEvent(new Event('m-refresh-widgets'));
//     };
//     document.head.appendChild(scriptElemenToAdd);
//   }
// }

if (window) {
  window.addEventListener('load', replaceWidget);
  window.addEventListener(MANIFOLD_LOAD_REQUEST, replaceWidget);
}
